// |Array| Make an enum for a given number of arguments. I.e. `makeEmun('open', 'closed')` will return `{ open: 'open', closed: 'closed' }`

function makeEnum() {
    var obj = {};
    Array.prototype.forEach.call(arguments, (v) => {
        obj[v] = v;
    });
    return obj;
}

export default makeEnum;