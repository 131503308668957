import shoogle from '../scripts/shoogle';
import enquire from '../scripts/enquire';
import arrows from '../scripts/arrows';
import pageSizeObservable from '../scripts/lib/page-size-observable';
import getPointInElementPosition from '../scripts/lib/get-point-in-element-position';

document.addEventListener('DOMContentLoaded', () => {

    var shooglerHtmlCollection = document.getElementsByTagName('a');

    Array.prototype.forEach.call(
        shooglerHtmlCollection,
        shoogle
    );

    const enquireHeaderEl = document.querySelector('#enquire .enquire-text h2 span');
    const enquireFormEl = document.getElementById('enquire-form');

    enquire(enquireFormEl);

    var arrowProvider = arrows(document.querySelector('#background'));
    var arrow = arrowProvider
        .makeArrow();

    const renderArrow = () => {
        const headerOffset = getPointInElementPosition(enquireHeaderEl, new DOMPoint(6,4), new DOMPoint(1, 0));
        const headerOffsetControl = getPointInElementPosition(enquireHeaderEl, new DOMPoint(230,4), new DOMPoint(1, 0));
        arrow.from(headerOffset.x, headerOffset.y, headerOffsetControl.x, headerOffsetControl.y);


        const originOffset = document.documentElement.scrollWidth < 768 ? 0.9 : 0; 
        const formOffset = getPointInElementPosition(enquireFormEl, new DOMPoint(0,-12), new DOMPoint(originOffset, -1));
        const formOffsetControl = getPointInElementPosition(enquireFormEl, new DOMPoint(0,-120), new DOMPoint(originOffset, -1));
        arrow.to(formOffset.x, formOffset.y, formOffsetControl.x, formOffsetControl.y);
    };

    renderArrow();


    pageSizeObservable()
        .subscribe(renderArrow);

});



