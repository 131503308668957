import domRender from "./lib/dom-render";
import animationFrameObservable from "./lib/animation-frame-observable";
import {
    filter,
    tap,
} from "rxjs";

function arrows(targetEl) {

    const color = "hsl(0, 0%, 80%)";
    const colorLight = "hsl(0, 0%, 90%)";

    const arrowDefinition = [
        'path',
        {
            d: "M1 14V2L7 8L1 14Z"
        }
    ];

    const arrowHeadDefinition = [
        'marker',
        {
            id: "arrowhead",
            markerWidth: 15,
            markerHeight: 15,
            refX: 4,
            refY: 7.5,
            orient: "auto",
            fill: colorLight,
            stroke: colorLight,
            'stroke-linejoin': "round",
        },
        [arrowDefinition]
    ];

    const defsDefinition = [
        'defs',
        null,
        [
            arrowHeadDefinition
        ]
    ];

    const svgDefinition = [
        'svg',
        {
            width: "100%",
            height: "100%",
            style: {
                pointerEvents: "none",
                width: '100%',
                height: '100%',
                position: "absolute",
                top: 0,
                left: 0,
            }
        },
        [defsDefinition]
    ];

    const svgEl = domRender(svgDefinition, targetEl);

    const updateObserver$ = animationFrameObservable();

    return {
        makeArrow: () => {

            const pathControls = [
                'M', 0, 0, 'C', 0, 0, 0, 0, 0, 0
            ];

            var props = {
                fill: "none",
                stroke: color,
                "stroke-width": "1",
                "marker-end": "url(#arrowhead)",
                'stroke-dasharray': "2 2",
            };

            var pathEl;
            var needsUpdate = true;

            var updateSubscription = updateObserver$
                .pipe(
                    filter(_ => needsUpdate),
                    tap(_ => needsUpdate = false)
                )
                .subscribe(_ => {
                    props.d = pathControls.join(' ');

                    if (!pathEl) {
                        // Create the line
                        const pathDefinition = [
                            'path',
                            props
                        ];
                        pathEl = domRender(pathDefinition, svgEl);

                    } else {
                        Object.keys(props).forEach(key => {
                            pathEl.setAttribute(key, props[key]);
                        });
                    }
                });

            var methods = {
                dispose: () => {
                    updateSubscription.unsubscribe();
                    if (pathEl) {
                        pathEl.remove();
                    }
                },
                from: (x, y, cx, cy) => {
                    pathControls[1] = x;
                    pathControls[2] = y;
                    pathControls[4] = cx;
                    pathControls[5] = cy;
                    needsUpdate = true;
                    return methods;
                },
                to: (x, y, cx, cy) => {
                    pathControls[6] = cx;
                    pathControls[7] = cy;
                    pathControls[8] = x;
                    pathControls[9] = y;
                    needsUpdate = true;
                    return methods;
                }
            }

            return methods;
        }
    }
}

export default arrows;