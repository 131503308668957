// |Array| Shuffles an array. Defaults to returning a new array. But can shuffle in place `shuffle(arr, inPlace=false)`.

// Fisher-Yates shuffle. Source http://stackoverflow.com/questions/962802/is-it-correct-to-use-javascript-array-sort-method-for-shuffling*

function shuffle (arr, inPlace) {
    var array = (inPlace === true) ? arr :  arr.slice(0);
    var tmp, current, top = array.length;
    if(top) while(--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
    }
    return array;
}

export default shuffle;