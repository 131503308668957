import {
    animationFrameScheduler,
    BehaviorSubject,
    catchError,
    combineLatest,
    EMPTY,
    filter,
    from,
    fromEvent,
    map,
    observeOn,
    startWith,
    switchMap,
    tap,
    timer,
    withLatestFrom
} from 'rxjs';
import {
    createDirectus,
    createItem,
    rest
} from '@directus/sdk';
import isValidEmail from './lib/is-valid-email';
import makeEnum from './lib/make-enum';
import endsWith from './lib/ends-with';
import shuffle from './lib/shuffle';
import makeTypewriter from './lib/make-typewriter';

function enquire(el) {
    makeForm(el);
    makePlaceholderMessageText(el);
}

function makeForm(el) {
    const buttonEl = el.querySelector('button');
    const emailEl = el.querySelector('.email');
    const messageEl = el.querySelector('.message');
    const spinnerEl = el.querySelector('loader');
    const buttonLabel = el.querySelector('span.label');

    const formStates = makeEnum(
        'empty',
        'hasUserContent',
        'canSend',
        'posting'
    );

    const formState$ = new BehaviorSubject(formStates.empty);
    const email$ = makeInputObservable(emailEl);
    const message$ = makeInputObservable(messageEl);
    const buttonClick = fromEvent(buttonEl, 'click');

    formState$.subscribe(v => {
        switch (v) {
            case formStates.empty:
                emailEl.disabled = false;
                messageEl.disabled = false;
                buttonEl.disabled = true;
                buttonEl.classList.add('disabled');
                el.classList.remove('has-user-content');
                buttonLabel.innerHTML = "Send";
                break;
            case formStates.hasUserContent:
                emailEl.disabled = false;
                messageEl.disabled = false;
                buttonEl.disabled = true;
                buttonEl.classList.remove('success');
                el.classList.add('has-user-content');
                buttonLabel.innerHTML = "Send";
                break;
            case formStates.canSend:
                buttonEl.classList.remove('success');
                buttonEl.classList.remove('disabled');
                emailEl.disabled = false;
                messageEl.disabled = false;
                buttonEl.disabled = false;
                buttonLabel.innerHTML = "Send";
                break;
            case formStates.posting:
                emailEl.disabled = true;
                messageEl.disabled = true;
                buttonEl.disabled = true;
                buttonLabel.innerHTML = "Sending";
                buttonEl.classList.add('sending');
                break;
            case formStates.success:
                buttonLabel.innerHTML = "👍 Sent, thank you. We'll be in touch soon&hellip;";
                buttonEl.classList.remove('sending');
                buttonEl.classList.add('success');
                timer(5000)
                    .pipe(
                        filter(_ => formState$.getValue() === formState$.success),
                        tap(_ => formState$.next(formStates.canSend))
                    )
                    .subscribe();
                break;
            case formStates.errored:
                buttonLabel.innerHTML = "Sorry, something went wrong. Please try again.";
                buttonEl.classList.remove('sending');
                timer(5000)
                    .pipe(
                        filter(_ => formState$.getValue() === formState$.errored),
                        tap(_ => formState$.next(formStates.canSend))
                    )
                    .subscribe();
                break

        }
    });

    // Submit to server
    const client = createDirectus('https://directus.electricglen.com').with(rest());

    buttonClick
        .pipe(
            withLatestFrom(email$, message$),
            map(([click, email, message]) => ({ email, message })),
            filter(({ message, email }) => isValidEmail(email) && message.length > 0),
            tap(() => {
                formState$.next(formStates.posting);
            }),
            switchMap(({ email, message }) => {
                const request$ = from(client.request(createItem('Enquiries', {
                    message,
                    email
                })));

                return request$.pipe(
                    catchError(error => {
                        console.error('Error submitting enquiry', error);
                        // Re-enable the inputs in case of an error
                        formState$.next(formStates.errored);
                        // Return an empty observable to terminate the chain
                        return EMPTY;
                    })
                );
            })
        )
        .subscribe(result => {
            console.log('Enquiry submitted successfully', result);
            formState$.next(formStates.success);
        });

    // Update whenever the text changes
    return combineLatest([email$, message$])
        .pipe(
            tap(([email, message]) => {

                const hasUserContent = email.length > 0 || message.length > 0;
                const formIsValid = isValidEmail(email) && message.length > 0;

                if (formIsValid) {
                    formState$.next(formStates.canSend);
                } else if (hasUserContent) {
                    formState$.next(formStates.hasUserContent);
                } else {
                    formState$.next(formStates.empty);
                }

            }),
        )
        .subscribe(([email, message]) => {});
}

function makePlaceholderMessageText(el) {

    // Update the placeholder text
    const messageEl = el.querySelector('.message');
    const messagesEl = el.querySelectorAll('.messages li');
    const messages = Array.prototype.map.call(messagesEl, (v) => v.innerHTML);

    var addCharacter = function (character) {
        // Add character to input placeholder
        if (endsWith(messageEl.placeholder, "|")) {
            messageEl.placeholder = messageEl.placeholder.slice(0, -1) + character + "|";
        } else {
            messageEl.placeholder = messageEl.placeholder + character + "|";
        }

        // Return null to skip internal adding of dom node
        return null;
    }

    var removeCharacter = function ({ character }) {
        if (messageEl.placeholder) {
            if (endsWith(messageEl.placeholder, "|")) {
                messageEl.placeholder = messageEl.placeholder.slice(0, -2) + "|";
            } else {
                messageEl.placeholder = messageEl.placeholder.slice(0, -1) + "|";
            }
        }
    }

    makeTypewriter({
        addCharacter,
        removeCharacter,
        strings: shuffle(messages)
    }).subscribe();
    
}

function makeInputObservable(inputElement) {
    return fromEvent(inputElement, 'input').pipe(
        observeOn(animationFrameScheduler),
        map(event => event.target.value),
        startWith('') // Emit initial value for combineLatest to work immediately
    );
}

export default enquire;